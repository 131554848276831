import type { LocalePrefix } from 'next-intl/dist/types/src/routing/types';

import type { LocaleWithISO639Prefix } from './types';

export const defaultLocale = 'en-US' as const satisfies LocaleWithISO639Prefix;

export const localePrefix = { mode: 'always' } satisfies LocalePrefix;

/** NOTE(rongsen): To be enabled in Q4. */
// const _futureLocales = [
//   'zh-HK',
//   'es-ES',
//   'ja-JP',
//   'en-GB',
//   'en-AU',
// ] as const satisfies readonly LocaleWithISO639Prefix[];

export const supportedLocales = [
  defaultLocale,
  'pt-BR',
  'zh-CN',
  'id-ID',
  // ...futureLocales /** NOTE(rongsen): To be enabled in Q4. */
] as const satisfies readonly LocaleWithISO639Prefix[];

export const symbolCookieL10n = 'NEXT_L10N' as const;

export const symbolHeaderLocales = 'x-locales' as const;
