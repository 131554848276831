import { IconStyle } from '@/config-schema/rewards-central-config'; 
export const fontawesomeIconMap: Map<IconStyle, string> = new Map(); 
fontawesomeIconMap.set('duotone', '/assets/icon/sprites/output/duotone.a2b0ff.svg'); 
fontawesomeIconMap.set('thin', '/assets/icon/sprites/output/thin.8c7e2d.svg'); 
fontawesomeIconMap.set('light', '/assets/icon/sprites/output/light.9130d3.svg'); 
fontawesomeIconMap.set('regular', '/assets/icon/sprites/output/regular.a69765.svg'); 
fontawesomeIconMap.set('solid', '/assets/icon/sprites/output/solid.e961d3.svg'); 
fontawesomeIconMap.set('sharp-duotone-solid', '/assets/icon/sprites/output/sharp-duotone-solid.aededf.svg'); 
fontawesomeIconMap.set('sharp-light', '/assets/icon/sprites/output/sharp-light.53c651.svg'); 
fontawesomeIconMap.set('sharp-regular', '/assets/icon/sprites/output/sharp-regular.978e88.svg'); 
fontawesomeIconMap.set('sharp-solid', '/assets/icon/sprites/output/sharp-solid.feb470.svg'); 
fontawesomeIconMap.set('sharp-thin', '/assets/icon/sprites/output/sharp-thin.39b89f.svg')