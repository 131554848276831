'use client';

import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { MenuItemGroupType, MenuItemType } from '@/config-schema/navigation';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import Drawer, { DrawerBody } from '@/design-system-components/drawer/drawer';
import { useHeaderLinks } from '@/hooks/config/use-header-links';

import { SupportedIconName } from '@/icons/supported-icon';
import { Icon } from '../icon';
import { maxItemsForSingleLayer } from './constants';
import { MobileSideNavigation } from './mobile-side-navigation';

export type MenuItemsPageType = {
  groupName?: string;
  groupType?: MenuItemGroupType;
  icon?: SupportedIconName;
  items: (MenuItemType | MenuItemsPageType)[];
};

export const MobileHeaderV2 = (
  props: RewardsCentralConfiguration['application']['components']['header'] & {
    features: RewardsCentralConfiguration['application']['features'];
    navigationConfiguration: RewardsCentralConfiguration['application']['components']['navigation'];
  },
) => {
  const {
    redeemHeaderLinks,
    earnHeaderLinks,
    headerLinks,
    accountHeaderLinks,
    needHelpLinks,
    homepageLink,
  } = useHeaderLinks(props.features, props.navigationConfiguration);
  const t = useTranslations('mobileHeaderV2');

  const closeMenuText = t('closeMenu');
  const openMenuText = t('openMenu');

  const [isOpen, setIsOpen] = useState(false);

  const menuItemsPage: MenuItemsPageType = useMenuItemsPage(
    redeemHeaderLinks,
    earnHeaderLinks,
    headerLinks,
    accountHeaderLinks,
    needHelpLinks,
    homepageLink,
  );

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button
        onClick={toggleDrawer}
        aria-expanded={isOpen}
        aria-label={isOpen ? closeMenuText : openMenuText}
        data-testid={isOpen ? 'close-menu' : 'open-menu'}
      >
        <Icon
          name="equals"
          fill={props.hamburgerIconColor ?? props.color}
          className="h-6 w-6"
        />
      </button>
      <Drawer
        variant="right"
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        dialogClassName="max-w-[375px] relative w-[80vw] bg-neutral-100"
      >
        <DrawerBody className="py-4 pb-8 pt-6">
          <MobileSideNavigation
            menuItemsPage={menuItemsPage}
            onClickMenuItem={toggleDrawer}
            avatar={props.avatar}
          />
        </DrawerBody>
      </Drawer>
    </>
  );
};

function useMenuItemsPage(
  redeemHeaderLinks: MenuItemType[],
  earnHeaderLinks: MenuItemType[],
  headerLinks: MenuItemType[],
  accountHeaderLinks: MenuItemType[],
  needHelpLinks: MenuItemType[],
  homepageLink?: MenuItemType,
) {
  const t = useTranslations('mobileHeaderV2');

  const redeemRewardsText = t('redeemRewards');
  const earnMoreText = t('earnMore');
  const myAccountText = t('myAccount');
  const needHelpText = t('needHelp');

  let navigationItemsPage: MenuItemsPageType = {
    items: [],
  };

  if (homepageLink) {
    navigationItemsPage.items.push(homepageLink);
  }

  if (headerLinks.length > maxItemsForSingleLayer) {
    if (redeemHeaderLinks.length > 0) {
      navigationItemsPage.items.push({
        groupName: redeemRewardsText,
        groupType: 'redeem',
        items: redeemHeaderLinks,
      });
    }
    if (earnHeaderLinks.length > 0) {
      navigationItemsPage.items.push({
        groupName: earnMoreText,
        groupType: 'earn',
        items: earnHeaderLinks,
      });
    }
  } else {
    navigationItemsPage.items.push({
      groupName: redeemRewardsText,
      groupType: 'redeem',
      items: headerLinks,
    });
  }

  navigationItemsPage.items.push(
    {
      groupName: myAccountText,
      groupType: 'myAccount',
      items: accountHeaderLinks,
    },
    {
      groupName: needHelpText,
      groupType: 'needHelp',
      icon: 'circle-question',
      items: needHelpLinks,
    },
  );

  return navigationItemsPage;
}

MobileHeaderV2.displayName = 'MobileHeaderV2';
